import {Link} from 'gatsby'
import React from 'react'
import BlogPostPreview from './blog-post-preview'
import NotePostPreview from './note-post-preview'
import tw, { theme, css } from 'twin.macro'

function BlogPostPreviewGrid(props) {
  return (
    <main tw="mb-24 md:mb-32">
      <section
        tw="mt-10 md:mt-24 font-serif"
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr min(1024px, calc(100% - 48px)) 1fr',
          gridRowGap: 8,
          '& > *': {
            gridColumn: 2,
          },
        }}
      >
        {props.nodes &&
          props.nodes.slice(0, 1).map(node => <BlogPostPreview {...node} key={node.id} />)}
      </section>
      <section
        tw="mt-4 font-serif grid"
        css={{
          gridTemplateColumns: '1fr min(1024px, calc(100% - 48px)) 1fr',
          '& > *': {
            gridColumn: 2,
          },
        }}
      >
        {props.nodes &&
          props.nodes.slice(1, props.nodes.length).map(node => (
            <article key={node.id}>
              <NotePostPreview {...node} />
            </article>
          ))}
      </section>
    </main>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid
