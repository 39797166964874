import { format } from 'date-fns'
import { Link } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image'
import { getBlogUrl } from '../lib/helpers'
import PortableText from './portableText'
// import urlBuilder from '@sanity/image-url'
import tw, { theme, css } from 'twin.macro'

function BlogPostPreview(props) {
  return (
    <article
      tw="pb-8 border-b"
      css={{
        borderColor: `${theme`borderColor.accent`}`,
      }}
    >
      <Link tw="block" to={getBlogUrl(props.slug.current)}>
        {props.mainImage && props.mainImage.asset && (
          <figure
            tw="rounded transform transition duration-150 ease-in-out md:hover:shadow-lg md:motion-safe:hover:scale-100 md:scale-95"
            style={{
              position: 'relative',
              overflow: 'hidden',
              margin: 0,
            }}
          >
            <div
              style={{
                paddingBottom: '56.25%',
              }}
            ></div>
            <SanityImage
              {...props.mainImage}
              css={{
                '&[data-lqip]': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                },
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </figure>
        )}
      </Link>
      <div tw="gap-x-2 flex flex-col md:flex-row-reverse md:place-items-stretch md:items-start mt-4">
        <div tw="flex-auto md:w-5/6">
          <h2 tw="prose">
            <Link
              to={getBlogUrl(props.slug.current)}
              tw="text-2xl lg:text-3xl underline text-primary"
              css={{
                color: `${theme`colors.accent`}`,
                '&:hover': {
                  color: `${theme`colors.primary`}`,
                },
              }}
            >
              {props.title}
            </Link>
          </h2>
        </div>
        <div tw="flex-none mt-1 font-sans text-sm lg:text-sm text-softer md:w-1/6">
          {format(new Date(props.publishedAt), 'do MMM yyyy')}
        </div>
      </div>

      <div tw="gap-x-2 flex flex-col md:flex-row-reverse justify-start">
        <div tw="md:w-5/6">
          {props._rawExcerpt && (
            <div tw="mt-4 prose prose-lg text-softer">
              <PortableText blocks={props._rawExcerpt} />
            </div>
          )}
        </div>
        <div tw="flex-none md:w-1/6"></div>
      </div>
    </article>
  )
}

export default BlogPostPreview
