import { format } from 'date-fns'
import { Link } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image'
import { getBlogUrl } from '../lib/helpers'
import PortableText from './portableText'
// import urlBuilder from '@sanity/image-url'
import tw, { theme, css } from 'twin.macro'

function NotePostPreview(props) {
  return (
      <div tw="gap-x-2 flex flex-col md:flex-row-reverse md:place-items-stretch md:items-center mt-8">
				<div tw="flex-auto md:w-5/6">
					<h3 tw="prose">
				<Link to={getBlogUrl(props.slug.current)} tw="text-xl lg:text-2xl mt-2 underline my-0"
						css={{
							color: `${theme`colors.accent`}`,
							'&:hover': {
							color: `${theme`colors.primary`}`,
							}
						}}>
					{props.title}
				</Link>
				</h3>
				</div>

				<div tw="flex-none font-sans text-sm mt-1 md:mt-0 text-softer md:w-1/6">{format(new Date(props.publishedAt), 'do MMM yyyy')}
				</div>
    </div>
  )
}

export default NotePostPreview
